<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
    </template>
    <portal to="v-main">
      <goodsDialog v-if="showEditDialog" v-model="showEditDialog" :id="idEdit" />
    </portal>
    <a-table-f-api ref="table" :api="url" :model="model" :useQuery="false" :defaults="defaults" @click="onClickRow($event)" />
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {
    goodsDialog: () => import("./dialogs/storageGoodsDialog"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      title: "",
      model: this.$store.getters["config/get"].models.storageGoods.list,
      url: "/accounting/dir/good",
      defaults: {
        sort: { key: "id", order: "DESC" },
      },
    };
  },
  created() {
    this.$root.title = "Товары";
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
